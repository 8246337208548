
.banner-container {
  width: 100%;
  margin-top: 64px;
  height: 48px;
  background-color: #3e8ed0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  z-index: 10;
}

.banner-container:hover {
  background-color: #4799dc;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .banner-container {
    font-size: 2vmax;
  }
}

.promo-item {
  padding: 4px;/* 
  color: #3c3cff; */
}

.promo-link {
  text-decoration: underline;
}

