
.realisations-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #0C4739;
}

@media screen and (min-width: 800px) {
    .realisations-icon {
        padding-right: 32px;
    }
}

.realisations-title-container {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 64px;
}

.realisations-title {
    color: white;
    font-size: 32px;
}

.realisations-content {
    margin-right: auto;
    margin-left: auto;
}

.text {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 64px !important;
    max-width: 1024px;
}
