.image-slideshow-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image-slideshow-loading {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #0C4739;
  z-index: 2;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.image-slideshow-loading.hidden {
  opacity: 0 !important;
  display: none;
}

.image-slideshow-loading-spinner {
  margin: auto;
}

.image-slideshow-current {
  background-color: #282c34;
  width: 100% !important;
  height: 70vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-slideshow-nav {
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 25vw;
  opacity: 0.5;
  z-index: 50;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.image-slideshow-prev {
  left: 0;
}

.image-slideshow-next {
  right: 0;
}

.image-slideshow-nav.hidden {
  opacity: 0;
}

.image-slideshow-nav:hover {
  opacity: 0.95;
}

.image-slideshow-nav-button {
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.image-slideshow-nav-icon {
  margin: auto;
}

.image-slideshow-prev-icon {
  margin: auto auto auto 5vw;
}

.image-slideshow-next-icon {
  margin: auto 5vw auto auto;
}


.image-slide {
  position: absolute;
  max-width: 100%;
  max-height: 70vh;
  background-size: cover;
  background-position: center;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.image-slide.active {
  opacity: 1 !important;
}

.image-slideshow-thumbnails {
  margin-top: 16px;
  padding-bottom: 24px;
  height: 172px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.image-slideshow-thumbnail {
  cursor: pointer;
  border-radius: 4px;
  margin: 4px;
  min-height: 132px;
  height: 132px;
  min-width: 132px;
  position: relative;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.image-slideshow-thumbnail.active {
  border: 4px solid white;
  max-height: 124px !important;
}

.image-slideshow-thumbnail-container > .tall {
  width: 100% !important;
  height: auto !important;
  max-height: 132px;
}

.image-slideshow-thumbnail-container > .wide {
  height: 100% !important;
}
